<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
    <v-footer>
      <v-bottom-navigation
        z-index="100"
        :elevation="0"
        v-model="valueNav"
        color="#FF8351"
        grow
        border
        height="80"
      >
        <v-btn value="home" plain>
          <v-icon>mdi-home</v-icon>

          <span>Главная</span>
        </v-btn>

        <v-btn value="base">
          <v-icon>mdi-file-document-outline</v-icon>

          <span>База</span>
        </v-btn>

        <v-btn value="analitics">
          <v-icon>mdi-chart-line</v-icon>

          <span>Аналитика</span>
        </v-btn>
        <v-btn value="profile">
          <v-icon>mdi-card-account-details</v-icon>

          <span>Профиль</span>
        </v-btn>
      </v-bottom-navigation>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    valueNav: "home",
  }),
};
</script>
